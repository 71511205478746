import * as React from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';
import Headline from '../../components/Headline/Headline';
// @ts-ignore
import * as styles from './welcome.module.css';
import {
    fetchTopApplications
} from '../../api/application';
import {
    getAuthUser, setAuthUser
} from '../../helpers/user';
import {
    useEffect, useState
} from 'react';
import {votingCategories} from '../../components/VoteBar/VoteBar';
import CheckboxGroup from '../../components/formElements/CheckboxGroup/CheckboxGroup';
import {Form, Formik} from 'formik';
import FormSubmit from '../../components/formElements/FormSubmit/FormSubmit';
import {apiPostAuthenticated} from '../../api/api';
import {fetchUser} from '../../api/user';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';

const welcome = ({setAuthenticatedUser,}) => {
    const [user, setUser,] = useState(getAuthUser());

    const [topApplications, setTopApplications,] = useState([]);

    useEffect(
        () => {
            (async () => {
                const result = await fetchTopApplications();
                if (result.success) {
                    const topApplications = result.applications;
                    setTopApplications(topApplications);
                }
            })();
        },
        []
    );

    useEffect(() => {
        if (user.accepted_welcome_privacy) {
            navigate('/jury');
        }
    }, [user,]);


    return (
        <DefaultPage phase={process.env.GATSBY_PHASE}>
            {/* Container */}
            <div className={`grid-8--desktop grid-8--tablet grid-2--mobile  ${styles.welcome__container}`}>
                {/* Headline */}
                <Headline headline="Herzlich Willkommen"/>
                {/* Subtitle */}
                <h2 className={styles.welcome__subtitle}>{user?.name}</h2>
                <p>
                    Wir freuen uns über Ihre Teilnahme als Jurymitglied im Rahmen des FOCUS Innovationspreises 2021!
                    Bevor es los geht, möchten wir Sie gerne über den Voting Prozess informieren.
                </p>
                {/* Second Paragraph */}
                <p className={styles.welcome__secondParagraph}>
                    Sie bewerten jede der TOP {topApplications.length} Innovationen nach den folgenden 5 Kriterien:
                </p>

                {/* List */}
                <ul className={styles.welcome__list}>
                    {
                        votingCategories.map(category => {
                            return <li>
                                <b>{category}</b>
                            </li>;
                        })
                    }
                </ul>

                <p>
                    Für jedes Kriterium können Sie Punkte von 1 - 10 vergeben (1 am schlechtesten und 10 am besten).
                    Jede Innovation muss vollständig bewertet werden.
                </p>

                <p>
                    Per Klick gelangen Sie auf die Detailseite der jeweiligen Innovation. Dort können Sie das Voting
                    abgeben.
                </p>

                <p className={styles.welcome__lastParagraph}>
                    Ihr Voting wird automatisch zwischengespeichert. So können Sie jederzeit pausieren und zu einem
                    späteren Zeitpunkt weitermachen. Wenn Sie Hilfe benötigen können Sie den Hilfe Link oben rechts auf
                    der Seite benutzen, um uns zu kontaktieren. Los geht’s!
                </p>

                {/* Button */}
                <div className={styles.welcome__button}>
                    <div>
                        <Formik
                            validate={
                                () => {
                                    const errors = {} as any;
                                    // @ts-ignore
                                    if (!document.querySelector('[name="privacy[welcome]"]').checked) {
                                        errors.privacy = 'Bitte bestätigen Sie diese Angabe';
                                    }
                                    return errors;
                                }}
                            initialValues={{
                                privacy: null,
                            }}
                            onSubmit={async () => {
                                const result = await apiPostAuthenticated('/jury/accept-welcome-privacy');
                                if (result.success) {
                                    const user = await fetchUser();
                                    if (user) {
                                        setAuthUser(user);
                                        setAuthenticatedUser({
                                            user: user,
                                        });
                                        setUser(user);
                                    }
                                }
                            }}>
                            {() => {
                                return (
                                    <Form>
                                        <CheckboxGroup
                                            name="privacy"
                                            options={[{
                                                'value': 'welcome',
                                                label: 'Hiermit bestätige ich, dass ich die Inhalte der Bewerbung ausschließlich zur Bewertung des FOCUS Innovationspreises nutze und alle Daten vertraulich behandle. (Pflichtfeld)',
                                            },]}
                                            onChange={() => {
                                            }}/>

                                        <FormSubmit
                                            className={styles.submit}
                                            label={`Zu den Top ${topApplications.length} Innovationen`}
                                        />
                                    </Form>);
                            }}
                        </Formik>
                    </div>


                </div>
            </div>
        </DefaultPage>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setAuthenticatedUser: ({
            user,
        }) => {
            return dispatch({
                type: 'SET_AUTHENTICATED_USER',
                payload: {
                    authenticatedUser: user,
                },
            });
        },
    };
};

export default connect(null, mapDispatchToProps)(welcome);
